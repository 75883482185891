
import { useState,useEffect } from "react";
import Spinner from 'react-bootstrap/Spinner';
import api from './api/posts';
import loginapi from './api/login';
import newStudentApi from './api/newStudentApi';
import { APISERVERURL } from './APIServerURL';

function StudentDownloadAdmit({accessToken,setAccessToken,setRefreshFailed}) {
  const [isAppLoaded,setIsAppLoaded]=useState(true);
  const session='2024-ODD';
  function downloadFile(fname)
  {
    fetch(`${APISERVERURL}/pdf/${fname}`).then((response) => {
      response.blob().then((blob) => {         
          // Creating new object of PDF file
          const fileURL =
              window.URL.createObjectURL(blob);
               
          // Setting various property values
          let alink = document.createElement("a");
          alink.href = fileURL;
          alink.download = fname;
          alink.click();
          setIsAppLoaded(true);
      });
    });
  }
const getPdf=async (e)=>{
      e.preventDefault();
      try{
        //alert(img+" "+postId);
        //post request to deletePhoto wit {id,fileName}
        setIsAppLoaded(false);
        const response= await newStudentApi.post('/downloadAdmit',{session:session},{headers:{'Authorization':`Bearer ${accessToken}`}});    
        alert(response.data);
        downloadFile(response.data);
      }
      catch(err)
      {
        try{
          const config = { withCredentials: true };
          const x=await loginapi.get('/getAccessToken',config);
          //alert(JSON.stringify(x['data']['accessToken']));
          
          const nat=x['data']['accessToken'];
          setAccessToken(nat);
          //console.log(`ne at ${nat}`);
          //console.log(`Second at:${accessToken}`);
          //alert('ok');
          try{       
            const response= await newStudentApi.post('/downloadAdmit',{session:session},{headers:{'Authorization':`Bearer ${nat}`}});    
            //alert(JSON.stringify(response.data));
            downloadFile(response.data);
          }
          catch(err)
          {
            alert('unusual error newposts.js');
            setIsAppLoaded(true);
            //alert(JSON.stringify(err));
            //navigate('/');
          }
        }
        catch(err)
        {
          alert(JSON.stringify(err));
          setIsAppLoaded(true);
          //setError(JSON.stringify(err));
          //navigate('/');
        }

      }
      finally{
        setIsAppLoaded(true);
      }
    }
  
  return (
      <main>
        <div className="verticaldiv centrediv fullWidth ">
        <div className="fullWidth centeredDiv"><h2>DOWNLOAd ADMIT CARD</h2></div>
        <button onClick={getPdf} className="btn btn-success">Download</button>     
        {
        !isAppLoaded &&
          <div className='spinneroverlay'>
            <div className='spinnerbody'>
              <Spinner animation="border" variant="info" />
              <p>Please wait. It might take a few seconds.</p>
            </div>           
          </div>
        }
        </div>
      </main>
    )
  }
  
  export default StudentDownloadAdmit