
import { useState,useEffect } from "react";
import Spinner from 'react-bootstrap/Spinner';
import api from './api/posts';
import loginapi from './api/login';
import { APISERVERURL } from './APIServerURL';



function AttendanceSheet({accessToken,setAccessToken,setRefreshFailed,setIsAppLoaded}) {
  const [code,setCode]=useState('');
  const [students,setStudents]=useState([]);
  const [message,setMessage]=useState('');
  const [selectedCollege,setSelectedCollege]=useState('');
  const [selectedCollegeName,setSelectedCollegeName]=useState('');
  
  
   const colleges=[
    {
      code:105,
      title:"Barnagar College, Sorbhog"
    },
    {
      code:102,
      title:"B. H. College, Barpeta"
    },{
      code:701,
      title:"Bhattadev College, Bajali"
      },
      {
          code:106,
          title:"Baosi Banikanta Kakati College (BBK) , Nagaon"
      },
      {
          code:101,
          title:"Madhab Choudhury College (M.C. College), Barpeta"
      }
  
  ];
  function onCollegeChange(e)
  {
    setSelectedCollege(e.target.value);
    const college = colleges.find((col) => col.code == e.target.value); 
    //alert(JSON.stringify(college));alert(JSON.stringify(e.target.value));
    if(college && college.title)
    {
      setSelectedCollegeName(college.title||'');

    }
    else{

      setSelectedCollegeName('');
    }
  }
  function getDDLColleges()
  {    
    return (<select className="borderLess" value={selectedCollege} id='ddlProgram'
      onChange={(e)=>{onCollegeChange(e)}}
            >
      <option key={'none'} value={''}>
            {'SELECT ONE'}
      </option>
      {colleges.map((college) => (
          <option key={college.code} value={college.code}>
            {college.title}
          </option>
        ))}
      </select>);
  }


  function downloadFile(fname)
  {
    fetch(`${APISERVERURL}/pdf/${fname}`).then((response) => {
      response.blob().then((blob) => {         
          // Creating new object of PDF file
          const fileURL =
              window.URL.createObjectURL(blob);
               
          // Setting various property values
          let alink = document.createElement("a");
          alink.href = fileURL;
          alink.download = fname;
          alink.click();
          setIsAppLoaded(true);
      });
    });
  }
const getPdf=async ()=>{
      try{
        //alert(img+" "+postId);
        //post request to deletePhoto wit {id,fileName}
        //setIsAppLoaded(false);
        const response= await api.post('/downloadEnrolledStudentsInACourse',{code:code,college:selectedCollege},{headers:{'Authorization':`Bearer ${accessToken}`}});    
        alert(response.data);
        downloadFile(response.data);
      }
      catch(err)
      {
        try{
          const config = { withCredentials: true };
          const x=await loginapi.get('/getAccessToken',config);
          //alert(JSON.stringify(x['data']['accessToken']));
          
          const nat=x['data']['accessToken'];
          setAccessToken(nat);
          //console.log(`ne at ${nat}`);
          //console.log(`Second at:${accessToken}`);
          //alert('ok');
          try{       
            const response= await api.post('/downloadEnrolledStudentsInACourse',{code:code,college:selectedCollege},{headers:{'Authorization':`Bearer ${nat}`}});    
            alert(JSON.stringify(response.data));
            downloadFile(response.data);
          }
          catch(err)
          {
            alert('unusual error newposts.js');
            setIsAppLoaded(true);
            //alert(JSON.stringify(err));
            //navigate('/');
          }
        }
        catch(err)
        {
          alert(JSON.stringify(err));
          //setIsAppLoaded(true);
          //setError(JSON.stringify(err));
          //navigate('/');
        }

      }
      finally{
        //setIsAppLoaded(true);
      }
    }

    
  const StudentList = ({ students }) => {
    return (
      <div style={{ margin: '20px' }}>
        <h2>Student List</h2><button className="link-button" onClick={getPdf}>DOWNLOAD</button>
        <table border="1" style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr style={{ backgroundColor: '#f2f2f2' }}>
              <th style={{ padding: '10px' }}>Sl. No</th>
              <th style={{ padding: '10px' }}>Roll Number</th>
              <th style={{ padding: '10px' }}>Name</th>
            </tr>
          </thead>
          <tbody>
            {students.map((student, index) => (
              <tr key={student._id}>
                <td style={{ padding: '10px', textAlign: 'center' }}>{index + 1}</td>
                <td style={{ padding: '10px', textAlign: 'center' }}>{student.rollno}</td>
                <td style={{ padding: '10px' }}>{student.name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };


  const onTxtCodeChange=(e)=>{
    setStudents([]);
    setCode(e.target.value);
    setMessage('');
  }
  const handleGo=async ()=>{
    if(selectedCollege==='' || code==='')
    {
      alert('Invalid Inpput!');
      return;
    }
    try{       
        //const response=await api.get('/stats',{headers:{'Authorization':`Bearer ${accessToken}`}});
        const response=await api.post('/getEnrolledStudentsInACourse',{code:code,college:selectedCollege},{headers:{'Authorization':`Bearer ${accessToken}`}});                            
        //alert(JSON.stringify( response.data)); 
        setStudents(response.data);setMessage('OK');
        //alert(response.data);
        }
        catch(err)
        {
          try{
            const config = { withCredentials: true };
            const x=await loginapi.get('/getAccessToken',config);            
            const nat=x['data']['accessToken'];
            const ufn=x['data']['userFullName'];
            const un=x['data']['userName'];
            const lt=x['data']['loginType'];
            //alert(nat);
            setAccessToken(nat);
            setRefreshFailed(false);  
                    
           
              if(lt.toLowerCase()==="admin")
                {
                      try{       
                        //const response=await api.get('/stats',{headers:{'Authorization':`Bearer ${accessToken}`}});
                        const response=await api.post('/getEnrolledStudentsInACourse',{code:code,college:selectedCollege},{headers:{'Authorization':`Bearer ${nat}`}});                            
                        //alert(JSON.stringify( response.data)); 
                        setStudents(response.data);setMessage('OK');
                        //alert(response.data);
                    }
                    catch(err)
                    {
                      //alert('Student Not Found');
                      setAccessToken('');
                      setRefreshFailed(true);  
                    }
                  }
            }
            catch(err)
            {
              //alert('dd');
              setAccessToken(''); 
              setRefreshFailed(true);              
            }
        }         
         finally
            {
              setIsAppLoaded(true);
              //alert('app loaded'+accessToken);
            }
   }
    return (
      <main>
        <div className="fullWidth centeredDiv verticaldiv">
            <h3>COURSE WISE ATTENDANCE SHEET</h3>
            <div className="width1000 centeredDiv verticaldiv">
                <div className="inputGroup ">
                  <label className="marginTop" htmlFor="txtCode">Course Code:</label>
                  <input
                    type="text"
                    placeholder="Enter the course code"
                    className="borderLess"
                    id="txtCode"
                    required
                    value={code}
                    onChange={onTxtCodeChange}
                  />
                 
                </div>

                <div className="inputGroup ">
                <label className="marginTop"  htmlFor='ddlProgram'>SELECT YOUR COLLEGE:</label>
                {  getDDLColleges()}
                </div>

                <div className="actionBar marginTop">        
                  <button className="btn btn-success loginButton" onClick={handleGo}>GO</button>
                </div>

               {
               students && students.length>0 && 
               <div>
                  <StudentList
                  students={students}
                  ></StudentList>
               </div>
               }
               {
               (!students || students.length===0) && message!=='' &&
               <div>
                <p>NO STUDENTS TO DISPLAY</p>
               </div>
               }
            </div>

        </div>
      </main>
    )
  }
  
  export default AttendanceSheet